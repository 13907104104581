import React from 'react';
import { Box, Typography, Divider, Grid, SvgIcon } from '@material-ui/core';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { MapPin, Phone, Printer, Mail } from 'react-feather';

import { Page } from '../Components/Page';

import { useStyles } from './Styles/Page';
import { cuStyles } from './Styles/ContactUs';
import { marginStyles } from '../Common/Styles/Margins';

const ContactUs = () => {
  const classes = useStyles();
  const cuClasses = cuStyles();
  const marginClasses = marginStyles();

  const MapWithAMarker = withScriptjs(withGoogleMap(() =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 34.0312705, lng: -118.420422 }}
    >
      <Marker position={{ lat: 34.0312705, lng: -118.420422 }} />
    </GoogleMap>
  ));

  return (
    <Page className={classes.root} title='Contact Us'>
      <Typography variant='h3'>CONTACT US</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <Typography className={marginClasses.mb2} variant='h4'>OFFICE ADDRESS</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <MapWithAMarker
            googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBUc7jJ_PvS8fORd-6-Lju_YWR-CgX6cis'
            loadingElement={<div />}
            containerElement={<div className={cuClasses.mapContainer} />}
            mapElement={<div className={cuClasses.mapElement} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={marginClasses.mb5} variant='h5'>Pacific Document Signing Inc.</Typography>
          <Box mb={2} display='flex' alignItems='center'>
            <SvgIcon className={marginClasses.mr2} fontSize='small' color='primary'>
              <MapPin />
            </SvgIcon>
            <Typography variant='body2'>
              10801 National Blvd. Suite #510, Los Angeles, CA 90064
            </Typography>
          </Box>
          <Box mb={2} display='flex' alignItems='center'>
            <SvgIcon className={marginClasses.mr2} fontSize='small' color='primary'>
              <Phone />
            </SvgIcon>
            <Typography variant='body2'>
              Tel: (800)884-7587
            </Typography>
          </Box>
          <Box mb={4} display='flex' alignItems='center'>
            <SvgIcon className={marginClasses.mr2} fontSize='small' color='primary'>
              <Mail />
            </SvgIcon>
            <Typography variant='body2'>
              Email: <a href='mailto:info@pacdocsign.com'>info@pacdocsign.com</a>
            </Typography>
          </Box>
          <Typography className={marginClasses.mb5} variant='h5'>Office Hours</Typography>
          <Typography className={marginClasses.mb2} variant='body2'>
            MON - FRI :  7:00am - 7:30pm  PST
          </Typography>
          <Typography className={marginClasses.mb2} variant='body2'>
            SAT : 8:00am - 4:00pm  PST
          </Typography>
          <Typography className={marginClasses.mb2} variant='body2'>
            AFTER HOURS TEL : (310)-597-5511
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
};

export { ContactUs };
